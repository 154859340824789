import { makeAutoObservable, runInAction } from 'mobx';

import { SELECTED_ROLE } from '@/const/storageKeys';
import { UserRoles } from '@/data/user/role';
import { RolePermissions } from '@/data/user/roles-permissions';
import AbilityStore, { IAbilityStore } from '@/domain/core/auth/AbilityStore';
import { TUser, TUserPermission, TUserProfile, TUserRole, TUserV2 } from '@/types/user';
import { backendApi } from '@/utils/api';

export interface IUserStore {
	profile?: TUser['profile'];
	abilityStore;
	userReadyStatus: 'loading' | 'success' | 'error';
	id: number;
	isAdmin: boolean;
	isLeader: boolean;
	updateProfile: (newAvatar: TUserProfile) => void;
	checkUserConsents: () => void;
	consentVersion?: consentVersion[];
	subRoles?: TUserRole[];
	role: TUserRole;
	internationalProjectsAvailable: boolean;
	changeUserRole(roleId: string): void;
	updateEmailConfirmed(status: boolean): void;
	userSignedConsents: SelectedUserConsentDTOResponse;
	isConsentsSigned: boolean;
	isInternalUser: boolean;
	isEmailConfirmed: boolean;
}

export interface IAuthResponse extends TUserV2 {}

// src\features\admin\consents\helpers\statuses.ts
type consentVersion = {
	documentTypeId: 1 | 2 | 3;
	documentStatusId: number;
	id: number;
};

interface AvailableProjectsResponse {
	hasInternationalProjects: boolean;
	hasLeaderProjects: boolean;
}

interface RequestedUserConsentResponse {
	consentVersion: consentVersion;
	attributes: Array<{ id: number; name: string }>;
}

interface SelectedUserConsentResponse {
	id: number;
	consentDocumentVersion: {
		id: number;
		documentTypeId: number;
		name: number;
	};
	attributes: Array<{ id: number; attribute: { id: number; name: string } }>;
}

interface SelectedUserConsentPayload {
	consentDocumentVersionId: number;
	attributeIds: number[];
}

export type RequestedUserConsentDTOResponse = Array<RequestedUserConsentResponse>;

export type SelectedUserConsentDTOResponse = Array<SelectedUserConsentResponse>;

export type SelectedUserConsentDTOPayload = Array<SelectedUserConsentPayload>;

class User implements IUserStore {
	id: number;

	abilityStore: IAbilityStore;

	profile: TUser['profile'] = {} as TUser['profile'];

	role: TUserRole;

	userReadyStatus: 'loading' | 'success' | 'error' = 'loading';

	isConsentsSigned = null;

	isInternalUser = false;

	isEmailConfirmed = false;

	consentsVersion: consentVersion[] = [];

	internationalProjectsAvailable = false;

	leaderProjectsAvailable = false;

	userSignedConsents: SelectedUserConsentDTOResponse = [];

	subRoles: TUserRole[] = [];

	constructor() {
		makeAutoObservable(this);
	}

	get isGlobalAdmin() {
		return this.role?.name === UserRoles.GlobalAdmin;
	}

	get isAdmin() {
		return (
			this.role?.name === UserRoles.GlobalAdmin ||
			this.role?.name === UserRoles.Admin ||
			this.role?.name === UserRoles.ThemeAdmin ||
			this.role?.name === UserRoles.InternationalAdmin
		);
	}

	get isImportAdmin() {
		return this.role?.name === UserRoles.ImportAdmin;
	}

	get isSecurityAdmin() {
		return this.role?.name === UserRoles.SecurityAdmin;
	}

	get isTracker() {
		return (
			this.role?.name === UserRoles.Tracker ||
			this.role?.name === UserRoles.ThemeTracker ||
			this.role?.name === UserRoles.InternationalTracker ||
			this.role?.name === UserRoles.InternationalTrackerExpert
		);
	}

	get isLeader() {
		return this.role?.name === UserRoles.Leader || this.role?.name === UserRoles.InternationalLeader;
	}

	get isHeadTracker() {
		return this.role?.name === UserRoles.HeadTracker;
	}

	get isTopManager() {
		return this.role?.name === UserRoles.TopManager;
	}

	get isExpert() {
		return (
			this.role?.name === UserRoles.Expert ||
			this.role?.name === UserRoles.InternationalExpert ||
			this.role?.name === UserRoles.ThemeExpert ||
			this.role?.name === UserRoles.InternationalTrackerExpert
		);
	}

	get isEmployee() {
		return this.role?.name === UserRoles.Employee;
	}

	get isManager() {
		return this.role?.name === UserRoles.Manager;
	}

	get inAdminGroup() {
		return this.isAdmin || this.isTracker || this.isHeadTracker;
	}

	get isInternational() {
		return this.role?.name.includes('international');
	}

	async init() {
		return this.fetchUserData().catch(console.error);
	}

	get currentRole() {
		return this.role;
	}

	updateProfile = (newProfile: TUserProfile) => {
		this.profile = newProfile;
	};

	updateEmailConfirmed = (newStatus: boolean) => {
		this.isEmailConfirmed = newStatus;
	};

	getInitialRedirectPath() {
		if (!this.role?.name) return '/';

		switch (this.role.name) {
			case UserRoles.Leader:
				return '/projects';
			case UserRoles.InternationalLeader:
				return '/international/projects';
			case UserRoles.ThemeExpert:
			case UserRoles.Expert:
				return `/experts/${this.id}/expertises/new`;
			case UserRoles.InternationalExpert:
				return `/international/experts/${this.id}/expertises/new`;
			case UserRoles.InternationalTracker:
			case UserRoles.InternationalTrackerExpert:
			case UserRoles.InternationalAdmin:
				return '/international/projects';
			default:
				return '/projects';
		}
	}

	async fetchUserData() {
		if (typeof window === 'undefined') return;
		const selectedRole = localStorage.getItem(SELECTED_ROLE);

		try {
			const { data } = await backendApi.get<IAuthResponse>('/v2/users/profile');

			if (
				((data.consentsIsSigned || data.isInternal) && data.mainRole?.name === UserRoles.Leader) ||
				data.mainRole?.name === UserRoles.InternationalLeader
			) {
				const { data: availableProjects } = await backendApi.get<AvailableProjectsResponse>(
					'/projects/available'
				);

				if (availableProjects?.hasInternationalProjects) {
					this.internationalProjectsAvailable = true;
				}

				if (availableProjects?.hasLeaderProjects) {
					this.leaderProjectsAvailable = true;
				}
			}

			const roles = (data?.rolesInSelections ?? []).concat(data.mainRole);
			let role = data.mainRole;

			if (selectedRole) {
				role = data?.rolesInSelections?.find((x) => x.id === Number(selectedRole)) || role;
			}

			runInAction(() => {
				this.profile = data.profile;
				this.role = role ?? data.mainRole;
				this.id = data.id;
				this.abilityStore = new AbilityStore(this.role);
				this.isConsentsSigned = data.consentsIsSigned;
				this.subRoles = roles;
				this.isEmailConfirmed = data.emailConfirmed;
				this.isInternalUser = data.isInternal;
				this.userReadyStatus = 'success';
			});
		} catch (error) {
			const isInternational = window.location.pathname.startsWith('/international');
			const roleName = isInternational ? UserRoles.InternationalLeader : UserRoles.Leader;

			this.abilityStore = new AbilityStore({
				name: roleName,
				permissions: (RolePermissions[roleName].permissions ?? []) as TUserPermission[],
			});
			this.userReadyStatus = 'error';

			console.error('Ошибка при авторизации', error);
		}
	}

	validateExpert = async () => {
		if (!this.isExpert) return false;

		try {
			const response = await backendApi.get<boolean>(`/experts/check/user`);
			return response.data;
		} catch (e) {
			return true;
		}
	};

	checkUserConsents = async () => {
		try {
			if (!this.isConsentsSigned) {
				const { data } = await backendApi.get<RequestedUserConsentDTOResponse>('/v2/user-consents/sign');
				this.consentsVersion = data.map((consent) => consent.consentVersion);
				return;
			}
			const { data } = await backendApi.get<SelectedUserConsentDTOResponse>('/v2/user-consents');
			this.userSignedConsents = data;
		} catch (e) {
			console.error(e);
		}
	};

	setUserConsents = async (data: SelectedUserConsentDTOPayload) => {
		await backendApi.post<unknown>('/v2/user-consents/sign', data);
		this.fetchUserData();
	};

	setConsent = (bool: boolean) => {
		this.isConsentsSigned = bool;
	};

	changeUserRole = (roleId: number | string) => {
		const newRole = this.subRoles.find((x) => x.id === +roleId);

		if (newRole) {
			this.role = newRole;
			this.abilityStore = new AbilityStore(this.role);
		}
	};
}

export default new User();
